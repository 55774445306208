import { FC, RefObject, useEffect } from "react";
import { StyledQaWordCard } from "./styles";
import { formatTime, isAiGenerationType } from "./utils";
import { IPronSelection, IQaPron } from "./types";
import IArticle from "src/types/article";
import toast from "react-hot-toast";
import { ButtonV2 } from "src/styles/ButtonV2";
import IPronunciation from "src/types/pronunciation";

interface IProps {
    prons: IQaPron[];
    onPickup: (item: IQaPron) => void;
    onIgnore: (item: IQaPron) => void;
    selections: IPronSelection[];
    seekAudioToSeconds: (startTimeSeconds: number, endTimeSeconds?: number) => void;
    article?: IArticle;
    qaWordsWrapperRef: RefObject<HTMLDivElement>;
    onIgnoreAll: (item: IQaPron) => void;
    isPronPickedUp: (item: IQaPron) => boolean;
    getSelectionItemInfo: (item: IQaPron) => {
        selectionItem: IPronSelection | undefined;
        isIgnored: boolean;
        isPickedUp: boolean;
        isDisabled: boolean;
        isSelected: boolean;
        isActive: boolean;
    };
    shouldAutoPlay: boolean;
    getActiveItem: () => IQaPron | undefined;
    onEditPronunciation: (pronunciation: IPronunciation) => void;
}

const PronunciationsQaWords: FC<IProps> = ({
    prons,
    onPickup,
    onIgnore,
    selections,
    article,
    seekAudioToSeconds,
    getSelectionItemInfo,
    qaWordsWrapperRef,
    onIgnoreAll,
    isPronPickedUp,
    shouldAutoPlay,
    getActiveItem,
    onEditPronunciation,
}) => {
    useEffect(() => {
        const wrapperItem = qaWordsWrapperRef.current;
        const item = wrapperItem?.querySelector(".QA_CARD__active");
        if (wrapperItem && item) {
            wrapperItem.scrollTo({ top: (item as any).offsetTop + 20 - (wrapperItem.getBoundingClientRect().height - item.getBoundingClientRect().height) / 2, behavior: "smooth" });
        }

        if (shouldAutoPlay) {
            const activeItems = getActiveItem()?.diffItems;
            if (activeItems) {
                const startTime = activeItems[0].startTime!;
                const endTime = activeItems[activeItems.length - 1].endTime!;
                seekAudioToSeconds(startTime, endTime);
            }
        }
    }, [selections]);

    const onPickupClick = (item: IQaPron) => (e: any) => {
        e.preventDefault();

        onPickup(item);
    };

    const onIgnoreClick = (item: IQaPron) => (e: any) => {
        e.preventDefault();

        onIgnore(item);
    };

    const onIgnoreAllClick = (item: IQaPron) => (e: any) => {
        e.preventDefault();

        const isPickedUp = isPronPickedUp(item);

        if (isPickedUp) {
            toast(
                (t) => (
                    <div style={{ fontFamily: "Poppins, sans-serif" }}>
                        <p style={{ fontWeight: 600, fontSize: "17px" }}>Warning!</p>
                        <p>An earlier version of this pronunciation was picked up. By pressing "Ignore all" you will lose all previous pick-ups for this pronunciation.</p>
                        <p>
                            Word: "<span style={{ fontWeight: 500 }}>{item.word}</span>"
                        </p>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", marginTop: "25px", marginBottom: "10px" }}>
                            <ButtonV2
                                isRed
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    onIgnoreAll(item);
                                    toast.dismiss(t.id);
                                }}
                            >
                                Ignore All
                            </ButtonV2>
                            <ButtonV2
                                isGrey
                                style={{ marginLeft: "15px" }}
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    toast.dismiss(t.id);
                                }}
                            >
                                Cancel
                            </ButtonV2>
                        </div>
                    </div>
                ),
                {
                    duration: Infinity,
                    style: {
                        minWidth: "500px",
                    },
                },
            );
        } else {
            onIgnoreAll(item);
        }
    };

    console.log(prons);

    return (
        <div>
            {prons.map((item) => {
                const firstDiffItem = item.diffItems?.[0];
                const lastDiffItemIndex = (item.diffItems?.length || 1) - 1;
                const lastDiffItem = item.diffItems?.[lastDiffItemIndex];

                const startTime = firstDiffItem?.startTime;
                const endTime = lastDiffItem?.endTime;

                const itemTypeElem =
                    firstDiffItem?.type === "DELETION" && lastDiffItem?.type === "DELETION" ? (
                        <span style={{ padding: "0px 2px", color: "rgba(0, 0, 0, 0.7)", fontWeight: 400, background: "rgba(250, 210, 207, 0.5)" }}>(deletion)</span>
                    ) : (
                        ""
                    );

                const { isActive, isDisabled, isIgnored, isPickedUp, isSelected } = getSelectionItemInfo(item);

                const phonetics = isAiGenerationType(article) ? item.pron.aiPhonetic || "-" : item.pron.pronunciationComment || "-";

                const isMoreThanOneProns = prons.filter((p) => p.pron.pronunciationId === item.pron.pronunciationId).length > 1;

                return (
                    <StyledQaWordCard
                        className={isActive ? "QA_CARD__active" : ""}
                        isDisabled={isDisabled}
                        isIgnoreButtonActive={isIgnored}
                        isPickupButtonActive={isPickedUp}
                        isSelected={isSelected}
                        isActive={isActive}
                        key={`${item.pron.pronunciationId}__${firstDiffItem.id}`}
                    >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <p className="QA_WORD__timestamp">{startTime && endTime ? formatTime(startTime * 1000) : "--"}</p>

                            <button
                                onClick={() => onEditPronunciation(item.pron)}
                                style={{ outline: "none", background: "none", border: "none", padding: "0", margin: "0", cursor: "pointer", color: "#3a3a3a" }}
                            >
                                edit
                            </button>
                        </div>

                        <p className="QA_WORD__heading">Pronunciation {itemTypeElem}</p>

                        <div className="QA_WORD__words_group">
                            <div>
                                <p className="QA_WORD__word_heading">WORD</p>
                                <p className="QA_WORD__word">{item.word}</p>
                            </div>

                            <div>
                                <p className="QA_WORD__word_heading">PHONETICS</p>
                                <p className="QA_WORD__word">{phonetics}</p>
                            </div>
                            {!!item.pron.pronunciationLink && (
                                <div style={{ marginRight: "15px", marginLeft: "auto" }}>
                                    <p style={{ marginTop: "1px" }} className="QA_WORD__word_heading">
                                        URL
                                    </p>
                                    <p style={{ cursor: "pointer" }} onClick={() => window.open(item.pron.pronunciationLink, "_blank")} className="QA_WORD__word">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M8.33333 5V6.66667H4.16667V15.8333H13.3333V11.6667H15V16.6667C15 17.1269 14.6269 17.5 14.1667 17.5H3.33333C2.8731 17.5 2.5 17.1269 2.5 16.6667V5.83333C2.5 5.3731 2.8731 5 3.33333 5H8.33333ZM17.5 2.5V9.16667H15.8333L15.8333 5.34417L9.33925 11.8392L8.16074 10.6607L14.6541 4.16667H10.8333V2.5H17.5Z"
                                                fill="#091E42"
                                            />
                                        </svg>
                                    </p>
                                </div>
                            )}
                        </div>

                        <div className="QA_WORD__action_buttons">
                            <button className="QA_WORD__action_buttons-pickup" disabled={isDisabled} onClick={onPickupClick(item)}>
                                Pickup
                            </button>
                            {isMoreThanOneProns && (
                                <button className="QA_WORD__action_buttons-ignore-all" disabled={isDisabled} onClick={onIgnoreAllClick(item)}>
                                    Ignore all
                                </button>
                            )}
                            <button className="QA_WORD__action_buttons-ignore" disabled={isDisabled} onClick={onIgnoreClick(item)}>
                                Ignore
                            </button>

                            {((startTime && endTime) || null) && (
                                <button className="QA_WORD__action_buttons-play" disabled={isDisabled} onClick={() => seekAudioToSeconds(startTime!, endTime!)}>
                                    Play
                                </button>
                            )}
                        </div>
                    </StyledQaWordCard>
                );
            })}
        </div>
    );
};

export default PronunciationsQaWords;
